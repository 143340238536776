import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSlidersByUser as fetchSlidersApi } from '../../apicalls/slidersByUser';

export const fetchSlidersByUser = createAsyncThunk('sliders/fetchByUser', async () => {
    try {
        const data = await fetchSlidersApi();
        return data;
    } catch (error) {
        console.error('Error during fetch:', error);
        throw error;
    }
});

const initialState = {
    sliders: [],
    status: 'idle',  // 'idle' | 'loading' | 'loaded' | 'error'
    error: null
};

const sliderSlice = createSlice({
    name: 'sliders',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchSlidersByUser.pending]: (state) => {
            state.status = 'loading';
        },
        [fetchSlidersByUser.fulfilled]: (state, action) => {
            state.sliders = action.payload;
            state.status = 'loaded';
        },
        [fetchSlidersByUser.rejected]: (state, action) => {
            state.error = action.error.message;
            state.status = 'error';
            state.sliders = [];
        }
    }
});

export  const slidersReducer = sliderSlice.reducer;
