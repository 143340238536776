import {configureStore} from '@reduxjs/toolkit'
import { authReducer } from './slices/auth';
import { slidersReducer } from './slices/sliders';
const store = configureStore({
    reducer:{
        auth: authReducer,
        sliders: slidersReducer
    }
});

export default store ;