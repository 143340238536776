import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAuth } from '../../apicalls/auth';
import { fetchRegistr } from '../../apicalls/registration';
import { fetchMe } from '../../apicalls/fetchAuthMe';

export const fetchUserData = createAsyncThunk('auth/fetchUserData', async (params) => {
    const { data } = await fetchAuth(params);
    return data;
});

export const fetchRegistration = createAsyncThunk('auth/fetchRegistration', async (params) => {
    const { data } = await fetchRegistr(params);
    return data;
});

export const fetchAuthMe = createAsyncThunk('auth/fetchAuthMe', async () => {
    const { data } = await fetchMe();
    return data;
});

const initialState = {
    data: null,
    status: 'loading'
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.data = null;
            localStorage.removeItem('token'); // Удаляем токен при выходе
        }
    },
    extraReducers: {
        [fetchUserData.pending]: (state) => {
            state.data = null;
            state.status = 'loading';
        },
        [fetchUserData.fulfilled]: (state, actions) => {
            state.data = actions.payload;
            state.status = 'loaded';
        },
        [fetchUserData.rejected]: (state) => {
            state.data = [];
            state.status = 'error';
        },
        [fetchAuthMe.pending]: (state) => {
            state.data = null;
            state.status = 'loading';
        },
        [fetchAuthMe.fulfilled]: (state, actions) => {
            state.data = actions.payload;
            state.status = 'loaded';
        },
        [fetchAuthMe.rejected]: (state) => {
            state.data = null;
            state.status = 'error';
        },
        [fetchRegistration.pending]: (state) => {
            state.data = null;
            state.status = 'loading';
        },
        [fetchRegistration.fulfilled]: (state, actions) => {
            state.data = actions.payload;
            state.status = 'loaded';
        },
        [fetchRegistration.rejected]: (state) => {
            state.data = null;
            state.status = 'error';
        },
    },
});

export const { logout } = authSlice.actions;

export const selectIsAuth = (state) => state.auth.data;
export const authReducer = authSlice.reducer;