import urls from "../config/Config";

export const fetchAuth = async (body,callback) => {

    try {
        const response = await fetch(`${urls.API_BASE_URL}/auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });
        console.log(response);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        localStorage.setItem('token', data.token);;
        console.log('Response from Backend:', data);
        return {data:data.user}

    } catch (error) {
        callback(false)
        console.error('Error during fetch:', error);
    }
};