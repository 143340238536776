import React, { Component } from "react";
import "./styleCapabilities.css";
import { LaptopOutlined, DesktopOutlined, ShoppingCartOutlined, SignatureOutlined,BranchesOutlined,CodeOutlined } from '@ant-design/icons';
import pLogo from "../../img/pGradientLogo.svg";

class Capabilities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderList: []
    };
  }

  render() {
    const { data } = this.state;
    let sizeIconMidUp = "48px";
    let colorIconMidUp = "#0088ff"
    let sizeFont = "20px";

    return (
      <section className="capabilities">
        <div className="capabilities__wrapper">
          <h2 className="capabilities__title">Co umíme?</h2>
          <p className="capabilities__description">Plně pochopíme Váš byznys a zjistíme, kdo konkrétně je cílová skupina. Podle toho začneme navrhovat a následně produkt vytvoříme.</p>
          <div className="capabilities__container">
            <div className="capabilities__row">
              <div className="capabilities__col capabilities__col--end">
                <div className="capabilities__item capabilities__item--web">
                  <div className="capabilities__content">
                    <h4 className="capabilities__heading">Prezentační web</h4>
                  </div>
                  <div className="capabilities__icon">
                    <DesktopOutlined style={{ fontSize: sizeFont }} />
                  </div>
                </div>
                <div className="capabilities__item capabilities__item--web-app">
                  <div className="capabilities__content">
                    <h4 className="capabilities__heading">Webové aplikace</h4>
                  </div>
                  <div className="capabilities__icon">
                    <LaptopOutlined style={{ fontSize: sizeFont }} />
                  </div>
                </div>
                <div className="capabilities__item capabilities__item--mob-app">
                  <div className="capabilities__content">
                    <h4 className="capabilities__heading">E-shop</h4>
                  </div>
                  <div className="capabilities__icon">
                  <ShoppingCartOutlined style={{ fontSize: sizeFont }} />
                    
                  </div>
                </div>
              </div>
              <div className="capabilities__col capabilities__col--center hide-on-mobile capabilities__imageLogo">
                <img src={pLogo} width="100%" height="100%" alt="Logo" />
              </div>
              <div className="capabilities__col">
                <div className="capabilities__item capabilities__item--grafika">
                  <div className="capabilities__icon">
                    <SignatureOutlined style={{ fontSize: sizeFont }} />
                  </div>
                  <div className="capabilities__content">
                    <h4 className="capabilities__heading">Grafika a design</h4>
                  </div>
                </div>
                <div className="capabilities__item capabilities__item--branding">
                  <div className="capabilities__icon">
                  <BranchesOutlined style={{ fontSize: sizeFont }}/> 
                  </div>
                  <div className="capabilities__content">
                    <h4 className="capabilities__heading">Automatizace procesů</h4>
                  </div>
                </div>
                <div className="capabilities__item capabilities__item--cloud">
                  <div className="capabilities__icon">
                  <CodeOutlined style={{ fontSize: sizeFont }}/> 
                  </div>
                  <div className="capabilities__content">
                    <h4 className="capabilities__heading">Vývoj na míru</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="capabilities__footer">
              <a href="/jak-pracujeme" className="capabilities__button">Jak pracujeme</a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Capabilities;
