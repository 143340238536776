import urls from "../config/Config";

export const fetchMe = async () => {

    try {
        let token = localStorage.getItem('token');
        const response = await fetch(`${urls.API_BASE_URL}/me`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        localStorage.setItem('token',data.token);
        // console.log('Response from Backend:', data);
        return { data: data.user }

    } catch (error) {
        console.error('Error during fetch:', error);
    }
};