import urls from "../../config/Config";

const sliderStyle = {
  height: '74',
  objectFit: 'contain',
  suffixH: 'vh',
  width: '100',
  suffixW: '%',
  colorDotActive: '#000000',
  colorDot: '#808080',
  sizeDot: '20',
  sizeButtons: '60',
  posLeftBtn: '0',
  posRightBtn: '0',
  posTopBtn: '45',
};

const sliderDefinition = {
  arrows: true,
  autoplay: true,
  autoplaySpeed: 6000,
  dots: true,
  fade: false,
  infinite: true,
  pauseOnDotsHover: false,
  pauseOnFocus: false,
  pauseOnHover: false,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 500,
  swipeToSlide: false,
  touchMove: false,
  vertical: false,
  verticalSwiping: false,
};

const defaultCss = `
  .slick-prev,
  .slick-next {
    width: ${sliderStyle.sizeButtons + 'px'};
    height: ${sliderStyle.sizeButtons + 'px'};
    z-index: 300;
    background: none;
    border: none;
    color: transparent;
  }
  .slick-next{
    right: ${sliderStyle.posRightBtn + '%'};
  }
  .slick-prev{
    left:${sliderStyle.posLeftBtn + '%'};
  }

  .slick-prev::before {
    content: '';
    display: inline-block;
    background-image: url(${urls.API_BASE_URL}/static/img/prev.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: ${sliderStyle.sizeButtons + 'px'};
    height: ${sliderStyle.sizeButtons + 'px'};
    opacity: 1;
  }
  .slick-next::before  {
  content: '';
    display: inline-block;
    background-image: url(${urls.API_BASE_URL}/static/img/next.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: ${sliderStyle.sizeButtons + 'px'};
    height: ${sliderStyle.sizeButtons + 'px'};
    opacity: 1;
  }
  .carousel-control {
    display: none
  }
  .slick-list{
    width:100%!important;
    
  }
  .slick-arrow {
    position: absolute;
    top: ${sliderStyle.posTopBtn + '%'};
    z-index: 1;
    padding: 0px !important;
  }
    
  .slick-next{
    right: 0;
  }

  .slick-dots li button {
    border: none;
    background: transparent;
  }

  .slick-dots {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0px;
  }
  .slick-dots li button {
    border: none;
    background: ${sliderStyle.colorDot};
    border-radius: 50%;
    color: transparent;
    width: ${sliderStyle.sizeDot + 'px'};
    height: ${sliderStyle.sizeDot + 'px'};
    margin: 5px;
  }
  .slick-active button {
    border: none;
    background: ${sliderStyle.colorDotActive}!important;
    border-radius: 50%;
    color: transparent;
    width: ${sliderStyle.sizeDot + 'px'};
    height: ${sliderStyle.sizeDot + 'px'};
    margin: 5px;
  }
  `;

const sliderConfig = {
  sliderDefinition,
  sliderStyle,
  defaultCss,
};

export default sliderConfig;



// .slick-prev,
// .slick-next {
//   width: ${sliderStyle.sizeButtons + 'px'};
//   height: ${sliderStyle.sizeButtons + 'px'};
//   z-index: 300;
//   background: none;
//   border: none;
//   color: transparent;
// }
// .slick-next {
//   transform: translate(${sliderStyle.posRightBtn + '%'}, ${sliderStyle.posTopBtn + '%'});
// }
// .slick-prev {
//   transform: translate(${sliderStyle.posLeftBtn + '%'}, ${sliderStyle.posTopBtn + '%'});
// }
// .slick-prev::before {
//   content: url(${urls.API_BASE_URL}/static/img/prev.png);
//   font-size: ${sliderStyle.sizeButtons + 'px'};
//   opacity: 1;
// }
// .slick-next::after {
//   content: url(${urls.API_BASE_URL}/static/img/next.png);
//   font-size: ${sliderStyle.sizeButtons + 'px'};
//   opacity: 1;
// }
// .carousel-control {
//   display: none;
// }
// .slick-list {
//   width: 100%!important;
// }
// .slick-arrow {
//   position: absolute;
//   top: ${sliderStyle.posTopBtn + '%'};
//   z-index: 1;
// }
// .slick-next {
//   right: 0;
// }
// .slick-dots li button {
//   border: none;
//   background: transparent;
// }
// .slick-dots {
//   list-style: none;
//   display: flex;
//   justify-content: center;
//   padding: 0;
//   margin: 0px;
// }
// .slick-dots li button {
//   border: none;
//   background: ${sliderStyle.colorDot};
//   border-radius: 50%;
//   color: transparent;
//   width: ${sliderStyle.sizeDot + 'px'};
//   height: ${sliderStyle.sizeDot + 'px'};
//   margin: 5px;
// }
// .slick-active button {
//   border: none;
//   background: ${sliderStyle.colorDotActive}!important;
//   border-radius: 50%;
//   color: transparent;
//   width: ${sliderStyle.sizeDot + 'px'};
//   height: ${sliderStyle.sizeDot + 'px'};
//   margin: 5px;
// }