import React, { Component } from "react";
import "./style.css";
import { PictureOutlined, LaptopOutlined, OpenAIOutlined, } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, message } from 'antd';
import Cover from "./Cover";
import ProjectsSection from "./ProjectsSection";
import Capabilities from "./Capabilities";
import { sendEmail } from "../../apicalls/sendEmail";


class WelcomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: 'vip',
      lastName: 'v',
      email: 'vitalii.paparyha@bits2b.com',
      message: 'hello'
    };
    this.formRef = React.createRef();
  }

  handleSubmit = async (value) => {
    try {
      const response = await sendEmail(value, (data) => {
        if (data.message === 'successfully') {
          this.formRef.current.resetFields();
          message.open({
            type: 'success',
            content: 'Vaše zpráva byla odeslána, brzy vás budeme kontaktovat.',
          });
        }else{
          message.open({
            type: 'error',
            content: 'Vaše zpráva nebyla odeslána, zkuste později.',
          });
        }
      })
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const { data } = this.state;
    let sizeIconMidUp = "48px";
    let colorIconMidUp = "#8a9fd1"
    let sizeFont = "20px";

    return (<>
      <Cover />
      <div className="app" id="mainHome">
        <div className="main-section">
          <section className="section section--features">
            <div className="section__container">
              <h2 className="section__title">Naše služby</h2>
              <p className="section__description">Nabízíme kompletní řešení od návrhu po implementaci, která jsou přizpůsobena vašim specifickým potřebám.
                S námi získáte nejen profesionální vzhled vaší online prezentace, ale také funkční nástroje, které vám umožní lépe spravovat vaše denní operace a zvýšit vaši produktivitu.</p>
              <Capabilities />
              <div className="section section--features">
                <div className="section__container">
                  <h2 className="section__title">Naše pluginy</h2>
                  <p className="section__description">Rozšiřte možnosti vašeho e-shopu s našimi inovativními pluginy.
                    Nabízíme vám nástroje, které nejen zlepší vizuální stránku vaší stránky, ale také posílí interakci s klienty a optimalizují vaše obchodní procesy.
                    Staňte se lídrem ve svém oboru díky technologiím, které posouvají hranice možného.</p>
                  <div className="features">
                    <div className="features__item">
                      <div className="features__icon">
                        <PictureOutlined style={{ fontSize: sizeIconMidUp, color: colorIconMidUp }} />

                      </div>
                      <h2 className="features__title">Unikátní nastavitelný slider</h2>
                      <p className="features__description">Náš slider umožňuje přeměnit jakékoliv HTML bloky на interaktivní slidery, což zvyšuje vizuální přitažlivost vašeho e-shopu.</p>
                    </div>
                    <div className="features__item">
                      <div className="features__icon">
                        <LaptopOutlined style={{ fontSize: sizeIconMidUp, color: colorIconMidUp }} />
                      </div>
                      <h2 className="features__title">Konfigurátor zboží</h2>
                      <p className="features__description">Umožňuje zákazníkům kombinovat produkty podle kompatibility a specifikačních požadavků přímo ve vašem e-shopu.</p>
                    </div>
                    <div className="features__item">
                      <div className="features__icon">
                        <OpenAIOutlined style={{ fontSize: sizeIconMidUp, color: colorIconMidUp }} />

                      </div>
                      <h2 className="features__title">AI Bot (ve vývoji)</h2>
                      <p className="features__description">Naše nadcházející AI řešení pomůže zautomatizovat zákaznickou podporu, zajišťuje rychlé odpovědi на dotazy zákazníků a vede je к nákupu.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ProjectsSection />
          <h2 className="section__title">Kontakty</h2>
          <div className="contact-section">
            <div className="card">
              <div className="card-body">
                <div className="content">
                  <div className="contact-info">
                    <h1 className="title">Rádi bychom si <p className="title2line">s Vámi <strong>popovídali</strong><span className="dot">.</span></p></h1>
                    <p className="description">Zanechte nám na sebe kontakt a my se Vám rádi ozveme zpět.<p>Nemůžeme se dočkat, až spolu budeme spolupracovat.</p></p>
                    <div className="contact-row">info@papcraft.com</div>
                    <div className="contact-row">+420 608 816 443</div>
                    {/* <div className="contact-row">
                      <a href="https://www.linkedin.com/company/greipi" target="_blank" rel="noopener noreferrer" className="social-link">LinkedIn</a>
                    </div> */}
                  </div>
                  <div className="contact-form">
                    <Form
                      name="basic"
                      onFinish={this.handleSubmit}
                      layout="vertical"
                      ref={this.formRef}
                    >
                      <div className="form-row">
                        <div className="form-group half-width">
                          <Form.Item
                            label="Jméno"
                            name="firstName"
                            rules={[{ required: true, message: 'Zadejte své jméno!', },]} >
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="form-group half-width">
                          <Form.Item
                            label="Příjmení"
                            name="lastName"
                            rules={[{ required: true, message: 'Zadejte své příjmení!', },]} >
                            <Input />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="form-group">
                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[{ required: true, message: 'Zadejte svůj email!', type: 'email', message: 'Zadejte platný email!' }]}>
                          <Input />
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <Form.Item
                          label="Váš požadavek:"
                          name="message"
                          rules={[{ required: true, message: 'Zadejte váš požadavek!', },]} >
                          <Input.TextArea rows={4} />
                        </Form.Item>
                      </div>
                      <Form.Item
                        name="remember"
                        valuePropName="checked"
                        rules={[{ required: true, message: 'Musíte souhlasit s ochranou osobních údajů!', },]}
                      >
                        <Checkbox>Potvrzuji, že jsem se seznámil se zásadami ochrany osobních údajů.</Checkbox>
                      </Form.Item>
                      <div className="submit-button-wrapper">
                        <Form.Item>
                          <Button htmlType="submit" className="submit-button">Odeslat</Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </>
    );
  }
}



export default (WelcomePage);
