import urls from "../config/Config";

export const sendEmail = async (body, callback) => {
    try {
        const response = await fetch(`${urls.API_BASE_URL}/send-mail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });
        console.log(response);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        console.log('Response from Backend:', data);
        callback(data)

    } catch (error) {
        callback(false)
        console.error('Error during fetch:', error);
    }
};