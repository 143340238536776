// .slick-slide div {
//   position: relative;
//   width: ${sliderStyle.width + sliderStyle.suffixW};
//   height: ${sliderStyle.height + sliderStyle.suffixH};
//   margin: 0 auto;
//   overflow: hidden;
// }
// .slick-slide div img {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   inset: 0;
//   object-fit: ${sliderStyle.objectFit};
// }

import urls from "../../config/Config";


export const generateCSS = (sliderStyle,buttonImageUrls,user,sliderId) => {

    let leftImage =  buttonImageUrls.leftButtonImageUrl !== '' && buttonImageUrls.leftButtonImageUrl !== null ?`${urls.API_BASE_URL}/files/${user.id}/sliders/${sliderId}/leftButtonImage.png` :urls.API_BASE_URL + '/static/img/prev.png'
    let rightImage =  buttonImageUrls.rightButtonImageUrl !== '' && buttonImageUrls.rightButtonImageUrl !== null ?`${urls.API_BASE_URL}/files/${user.id}/sliders/${sliderId}/rightButtonImage.png` :urls.API_BASE_URL + '/static/img/next.png'
    return `
    
    .slick-prev,
    .slick-next {
      width: ${sliderStyle.sizeButtons + 'px'};
      height: ${sliderStyle.sizeButtons + 'px'};
      z-index: 300;
      background: none;
      border: none;
      color: transparent;
    }
    
    .slick-prev::before {
      content: '';
      display: inline-block;
      background-image: url(${leftImage});
      background-size: contain;
      background-repeat: no-repeat;
      width: ${sliderStyle.sizeButtons + 'px'};
      height: ${sliderStyle.sizeButtons + 'px'};
      opacity: 1;
    }
    .slick-next::before  {
     content: '';
      display: inline-block;
      background-image: url(${rightImage});
      background-size: contain;
      background-repeat: no-repeat;
      width: ${sliderStyle.sizeButtons + 'px'};
      height: ${sliderStyle.sizeButtons + 'px'};
      opacity: 1;
    }
    .carousel-control {
      display: none
    }
    .slick-list{
      width:100%!important;
      
    }
    .slick-arrow {
      position: absolute;
      top: ${sliderStyle.posTopBtn + '%'};
      z-index: 1;
      padding: 0px !important;
    }
      
    .slick-next{
      right: ${sliderStyle.posRightBtn + '%'};
    }
    .slick-prev{
      left:${sliderStyle.posLeftBtn + '%'};
    }

    .slick-dots li button {
      border: none;
      background: transparent;
    }

    .slick-dots {
      list-style: none;
      display: flex;
      justify-content: center;
      padding: 0;
      margin: 0px;
    }
    .slick-dots li:before {
      content: ""!important;
      font-size: 0px!important;
      margin: 0px!important;
    }
    .slick-dots li button {
      border: none;
      background: ${sliderStyle.colorDot};
      border-radius: 50%;
      color: transparent;
      width: ${sliderStyle.sizeDot + 'px'};
      height: ${sliderStyle.sizeDot + 'px'};
      margin: 5px;
    }
    .slick-active button {
      border: none;
      background: ${sliderStyle.colorDotActive}!important;
      border-radius: 50%;
      color: transparent;
      width: ${sliderStyle.sizeDot + 'px'};
      height: ${sliderStyle.sizeDot + 'px'};
      margin: 5px;
    }
    `;
  };
  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  export const generateResponsiveCSS = (sliderStyleDesktop, sliderStyleTablet, sliderStyleMobile,buttonImageUrls,user,sliderId) => {
    let css = generateCSS(sliderStyleDesktop,buttonImageUrls,user,sliderId);
    if (sliderStyleTablet && typeof sliderStyleTablet  === 'object' && !isEmptyObject(sliderStyleTablet)) {
      css += `
        @media (max-width: 1200px) {
          ${generateCSS(sliderStyleTablet,buttonImageUrls,user,sliderId)}
        }
      `;
    }
  
    if (sliderStyleMobile  && typeof sliderStyleMobile  === 'object' && !isEmptyObject(sliderStyleMobile)) {
      css += `
        @media (max-width: 768px) {
          ${generateCSS(sliderStyleMobile,buttonImageUrls,user,sliderId)}
        }
      `;
    }
  
    return css;
  };
  // .slick-next {
  //   transform: translate(${sliderStyle.posRightBtn + '%'}, ${sliderStyle.posTopBtn + '%'});
  // }
  // .slick-prev {
  //   transform: translate(${sliderStyle.posLeftBtn + '%'}, ${sliderStyle.posTopBtn + '%'});
  // }