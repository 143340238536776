import React from 'react';
import './GDPR.css';

const GDPR = () => {
  return (
    <div className="main-section">
      <section className="section section--gdpr">
        <div className="section__container">
          <h2 className="section__title " style={{ lineHeight: '3.8rem' }}>Zásady zpracování osobních údajů</h2>
          <p> Cílem těchto Zásad zpracování osobních údajů pro návštěvníky webových stránek <a href="https://www.papcraft.com/">www.papcraft.com</a> (dále jen „<b>Zásady</b>“) je poskytnout informace o tom, jaké osobní údaje subjekt <b>Papcraft</b> - Vitalii Paparyha, IČ 21383928, Luďka Matury 855, 530 12 Pardubice Studánka, fyzická osoba zapsaná v živnostenském rejstříku (dále jen „<b>správce</b>“) zpracovává o fyzických osobách při poskytování služeb, při návštěvách internetových stránek provozovaných správcem a kontaktech s potenciálními zákazníky, k jakým účelům a jak dlouho správce tyto osobní údaje v souladu s platnými právními předpisy zpracovává, komu a z jakého důvodu je může předat, a rovněž informovat o tom, jaká práva fyzickým osobám v souvislosti se zpracováním jejich osobních údajů náleží. </p>
          <br />
          <p> Tyto Zásady se týkají zpracování osobních údajů zákazníků, přiměřeně i jejich zástupců či kontaktních osob, uživatelů služeb, zájemců o služby a zboží správce a návštěvníků internetových stránek provozovaných správcem, a to vždy v rozsahu osobních údajů odpovídajícím jejich postavení vůči správci. </p>
          <br />
          <p> Tyto Zásady jsou účinné od 1. 5. 2024 a jsou vydány v souladu s nařízením (EU) 2016/679, o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a zákonem č. 110/2019, zákonem o zpracováních osobních údajů („<b>nařízení</b>“ nebo „<b>GDPR</b>“) za účelem zajištění informační povinnosti subjektu Papcraft jako správce dle čl. 13 GDPR. </p>
          <br />
          <h2 className="section__title gdpr-title-line-height">A. Kategorie osobních údajů</h2>
          <p> Osobním údajem je jakákoli informace, která se vztahuje k fyzické osobě, kterou je správce schopný identifikovat. V souvislosti s poskytováním služeb a prodejem zboží může ze strany správce dojít ke zpracování následujících kategorií osobních údajů. </p>
          <br />
          <h2 className="text-default"> 1. Základní osobní identifikační údaje a adresní údaje </h2>
          <br />
          <p> Takové údaje jsou nutné pro uzavření a plnění smlouvy. Jedná se zejména o tyto osobní údaje: </p>
          <br />
          <ul className="ul_moje">
            <li>akademický titul</li>
            <li>jméno a příjmení</li>
            <li>název obchodní firmy</li>
            <li>rodné číslo (v případě, že z jakýchkoli důvodů nebylo rodné číslo přiděleno, pak datum narození) </li>
            <li>IČO, DIČ</li>
            <li>adresa trvalého pobytu</li>
            <li>adresa sídla nebo místa podnikání</li>
            <li>fakturační adresa</li>
            <li>čísla předložených identifikačních dokladů a jejich kopie identifikační údaje zástupce zákazníka nebo kontaktní osoby, kterou zákazník určí </li>
            <li>identifikační údaje plátce vyúčtování</li>
            <li>bankovní spojení</li>
            <li>podpis</li>
          </ul>
          <p> V případě smluv na jednorázový prodej zboží je rozsah omezen na základní identifikační údaje. </p>
          <br />
          <h2 className="text-default"> 2. Kontaktní údaje </h2>
          <br />
          <ul className="ul_moje">
            <li>kontaktní telefonní číslo</li>
            <li>kontaktní e-mail</li>
            <li>kontakt na sociální sítě</li>
          </ul>
          <h2 className="text-default"> 3. Údaje o odebíraných službách, využívání služeb a platební morálce </h2>
          <br />
          <ul className="ul_moje">
            <li>druh a specifikace poskytované služby</li>
            <li>objem poskytnutých služeb a jejich cena</li>
            <li>zákaznický segment</li>
            <li>informace o platební morálce</li>
          </ul>
          <h2 className="text-default"> 4. Ostatní údaje generované v souvislosti s poskytováním služeb </h2>
          <br />
          <p> Tyto údaje vznikají při poskytování služeb, které nejsou službami elektronických komunikací, nebo při poskytování služeb elektronických. </p>
          <br />
          <h2 className="text-default"> 5. Údaje z komunikace mezi správcem a zákazníkem </h2>
          <br />
          <p> Tyto údaje vznikají při komunikaci související s poskytováním služeb a zboží mezi správcem a zákazníkem. Jedná se o zápisy osobní komunikace se zákazníkem na prodejnách nebo jiném přímém kontaktu se zákazníkem, písemnou a elektronickou komunikaci se zákazníkem a záznamy telefonických hovorů, chatové a videochatové komunikace mezi zákazníkem a správcem. </p>
          <br />
          <h2 className="text-default"> 6. Údaje zpracované na základě vašeho souhlasu </h2>
          <br />
          <p> Zpracování těchto údajů není nezbytně nutné k plnění smlouvy nebo zákonných povinností či ochranu oprávněných zájmů správce, ale jejich zpracování umožní správci zlepšovat služby, zaměřit se na to, co zákazníky opravdu zajímá, a případně zákazníky informovat o nabídkách, které jsou pro ně vhodné. Tyto údaje jsou zpracovány jen v případě udělení souhlasu a mohou být zpracovány po dobu platnosti tohoto souhlasu. Jedná se zejména o: </p>
          <ul className="ul_moje">
            <li> údaje získané marketingovými průzkumy (jsou zpracovány u zákazníků služeb na základě souhlasu se zpracováním osobních údajů pro obchodní účely) </li>
            <li> údaje o využívání služeb, produktů, výhod, bonusů a typovém chování při využívání služeb (jsou zpracovány u zákazníků služeb správce na základě souhlasu se zpracováním osobních údajů pro obchodní účely) </li>
            <li> kontaktní údaje</li>
            <li> záznamy o chování na internetových stránkách spravovaných správcem získané z cookies v případě povolení cookies ve webovém prohlížeči (jsou zpracovány pro vylepšení provozu internetových stránek provozovaných správcem, internetovou reklamu a v případě souhlasu se zpracováním osobních údajů pro obchodní účely jsou tyto údaje zpracovány společně s ostatními osobními údaji pro tento účel) </li>
          </ul>
          <br />
          <h2 className="section__title gdpr-title-line-height">B. Účely, právní důvody a doby zpracování osobních údajů</h2>
          <br />
          <p> Rozsah zpracovávaných údajů závisí na účelu zpracování. Pro některé účely je možné zpracovávat údaje přímo na základě smlouvy, oprávněného zájmu nebo na základě zákona (bez souhlasu), pro jiné pouze na základě souhlasu. </p>
          <br />
          <h2 className="text-default"> 1. Zpracovávání z důvodu plnění smlouvy, plnění zákonných povinností a z důvodu oprávněných zájmů správce </h2>
          <br />
          <p> Poskytnutí osobních údajů nutných pro plnění smlouvy, plnění zákonných povinností a ochranu oprávněných zájmů je povinné. Bez poskytnutí osobních údajů k těmto účelům by nebylo možné služby poskytovat. Ke zpracování osobních údajů pro tyto účely nepotřebujeme souhlas. Zpracování z důvodu plnění smlouvy a plnění zákonných povinností nelze odmítnout. </p>
          <br />
          <p> U zákazníků služeb správce, je správce oprávněn v případě, že mají splněny veškeré své závazky vůči němu, zpracovávat v zákaznické databázi jejich základní osobní, identifikační, kontaktní údaje, údaje o službách a údaje z jejich komunikace se správcem po dobu 5 let ode dne ukončení poslední smlouvy se správcem. </p>
          <br />
          <p> V případě zakoupení zboží od správce je správce oprávněn zpracovávat základní osobní, identifikační a kontaktní údaje zákazníka, údaje o zboží a údaje z komunikace mezi zákazníkem a správcem po dobu 4 let ode dne uplynutí záruční doby na zboží. </p>
          <br />
          <p> V případě jednání mezi správcem a potenciálním zákazníkem o uzavření smlouvy, které nebylo završeno uzavřením smlouvy, je správce oprávněn zpracovávat poskytnuté osobní údaje po dobu 3 měsíců od příslušného jednání. </p>
          <br />
          <p> Faktury vystavené správcem jsou v souladu s § 35 zákona č. 235/2004 Sb., o dani z přidané hodnoty archivována po dobu 10 let od jejich vystavení. Z důvodu nutnosti doložit právní důvod pro vystavení faktur jsou po dobu 10 let ode dne ukončení smlouvy archivovány i zákaznické smlouvy. </p>
          <br />
          <h2 className="text-default"> 2. Zpracovávání údajů zákazníků služeb správce se souhlasem pro obchodní účely účinným od 25. 5. 2018 (účinnost nařízení) </h2>
          <br />
          <p> U zákazníka služby správce s jeho souhlasem zpracováváme osobní údaje pro obchodní účely. Pro období od 25. 5. 2018 nabírá správce nový souhlas pro obchodní účely, který je účinný po tomto datu. Datum počátku účinnosti souhlasu se zpracováním osobních údajů pro obchodní účely je v textu souhlasu. </p>
          <br />
          <p> Poskytnutí souhlasu k obchodním účelům je dobrovolné a zákazník jej může po 25. 5. 2018 kdykoli odvolat. Tento souhlas zůstává v platnosti po dobu využívání zboží a služeb a následující 4 roky poté nebo do doby, dokud jej zákazník neodvolá. Pro obchodní účely mohou být na základě souhlasu zpracovány veškeré kategorie údajů uvedené v sekci A tohoto dokumentu (s výjimkou podpisu a kopií identifikačních dokladů), a to po dobu, po kterou je správce oprávněn tyto údaje evidovat pro účely poskytování služeb, plnění zákonných povinností a ochranu svých oprávněných zájmů, nejdéle však do odvolání souhlasu či do uplynutí doby 4 let ode dne ukončení smlouvy na služby poskytované správcem, pokud zákazník souhlas dříve neodvolá. Pokud správce údajů svůj souhlas odvolá, není tím dotčeno zpracování jeho osobních údajů ze strany správce pro jiné účely a na základě jiných právních titulů, v souladu s těmito zásadami zpracování osobních údajů. </p>
          <br />
          <p> Zákazník služby správce v případě, že umožňuje využití této služby uživatelům, potvrzuje v rámci udělení souhlasu se zpracováním osobních údajů pro obchodní účely, že je oprávněn souhlas ohledně údajů vztahujících se k uživatelům služby odlišným od zákazníka udělit. </p>
          <br />
          <h2 className="text-default"> 4. Zpracovávání cookies z internetových stránek provozovaných správcem </h2>
          <br />
          <p> V případě, že má subjekt ve svém webovém prohlížeči povoleny cookies, zpracováváme o něm záznamy chování z cookies umístěných na internetových stránkách provozovaných správcem, a to pro účely zajištění lepšího provozu internetových stránek správce a pro účely internetové reklamy správce. </p>
          <br />
          <h2 class="section__title gdpr-title-line-height"> C. Kategorie příjemců osobních údajů </h2>
          <br />
          <p> Správce při plnění svých závazků a povinností ze smluv využívá odborné a specializované služby jiných subjektů. Pokud tito dodavatelé zpracovávají osobní údaje předané od správce, mají postavení zpracovatelů osobních údajů a zpracovávají osobní údaje pouze v rámci pokynů od správce a nesmí je využít jinak. Jde zejména o vymáhání dlužných pohledávek, činnost znalců, advokátů, auditorů, správu IT systémů, internetovou reklamu nebo obchodní zastoupení. Každý takový subjekt pečlivě správce vybírá a s každým uzavírá smlouvu o zpracování osobních údajů, ve které má zpracovatel stanoveny přísné povinnosti k ochraně a zabezpečení osobních údajů. </p>
          <br />
          <p> Zpracovateli jsou společnosti se sídlem jak na území České republiky, tak se sídlem v členském státě Evropské unie nebo tzv. státech bezpečných. K předání a zpracování osobních údajů v zemích mimo území Evropské unie, dochází vždy v souladu s platnou legislativou. </p>
          <br />
          <p> Správce v rámci plnění svých zákonných povinností předává osobní údaje správním orgánům a úřadům stanoveným platnou legislativou. </p>
          <br />
          <h2 class="section__title gdpr-title-line-height"> D. Způsob zpracování osobních údajů </h2>
          <br />
          <p> Správce zpracovává osobní údaje ručně i automatizovaně. Správce vede evidenci veškerých činností, 
            a to jak ručních, tak automatizovaných, při kterých dochází ke zpracování osobních údajů. </p>
          <br />
          <h2 class="section__title gdpr-title-line-height"> E. Obchodní sdělení </h2>
          <br />
          <p> Pro obchodní sdělení správce nebo třetích stran užívá správce zkratku OS nebo jiné vhodné označení, ze
            kterého je zřejmé, že uvedené sdělení je obchodním sdělením ve smyslu platných právních předpisů. Z obchodních
            sdělení zaslaných správcem je vždy zřejmé, že správce je jejich odesílatelem. Obchodní sdělení můžeme zasílat
            buď na kontakty zákazníků na základě oprávněného zájmu správce, a to jen do doby než vyslovíte nesouhlas,
            nebo na základě výslovného souhlasu se zpracováním osobních údajů pro marketingové a obchodní účely. V zaslaných
            obchodních sděleních je rovněž kontakt pro odmítnutí zasílání těchto sdělení. </p>
          <br />  
          <h2 class="section__title gdpr-title-line-height"> F. Informace o právech subjektů údajů v souvislosti se zpracováním osobních údajů
            platných od 25. 5. 2018 </h2>
          <br />
          <p> Dle nařízení a zákona má subjekt údajů od 25. 5. 2018 v případě, že bude pro správce identifikovatelnou fyzickou
            osobou a prokáže svoji totožnost, následující práva. </p>
          <br />
          <h2 class="text-default"> 1. Právo na přístup k osobním údajům </h2>
          <br />
          <p> Dle čl. 15 GDPR bude mít subjekt údajů právo na přístup k osobním údajům, které zahrnuje jednak právo získat od
            správce: </p>
          <br />
          <ul className="ul_moje">
            <li> potvrzení, zda zpracovává osobní údaje, </li>
            <li> informace o účelech zpracování, kategoriích dotčených osobních údajů, příjemcích, kterým osobní údaje byly nebo
              budou zpřístupněny, plánované době zpracování, o existenci práva požadovat od správce opravu nebo výmaz osobních
              údajů týkajících se subjektu údajů nebo omezení jejich zpracování nebo vznést námitku proti tomuto zpracování,
              právu podat stížnost u dozorového úřadu, o veškerých dostupných informacích o zdroji osobních údajů, pokud
              nejsou získány od subjektu údajů, skutečnosti, že dochází k automatizovanému rozhodování, včetně profilování, o
              vhodných zárukách při předání údajů mimo EU, </li>
            <li> v případě, že nebudou nepříznivě dotčena práva a svobody jiných osob i kopii osobních údajů. </li>
          </ul>
          <br />
          <p> V případě opakované žádosti bude správce oprávněn za kopii osobních údajů účtovat přiměřený poplatek. </p>
          <br />
          <p> Právo na potvrzení o zpracování osobních údajů a na informace je možné uplatnit písemně na adresu sídla
            správce. </p>
          <br />
          <h2 class="text-default"> 2. Právo na opravu nepřesných údajů </h2>
          <br />
          <p> Dle čl. 16 GDPR bude mít subjekt údajů právo na opravu nepřesných osobních údajů, které o něm bude správce
            zpracovávat. Zákazník správce, má rovněž povinnost oznamovat změny svých osobních údajů a doložit, že k takové
            změně došlo. Zároveň je povinen poskytnout správci součinnost, bude-li zjištěno, že osobní údaje, které o něm
            zpracovává, nejsou přesné. Opravu provede bez zbytečného odkladu, vždy však s ohledem na dané technické
            možnosti. Žádost o opravu osobních údajů je možné uplatnit písemně na adresu sídla správce. </p>
          <br />
          <h2 class="text-default"> 3. Právo na výmaz </h2>
          <br />
          <p> Dle čl. 17 GDPR bude mít subjekt údajů právo na výmaz osobních údajů, které se ho týkají, pokud správce neprokáže
            oprávněné důvody pro zpracování těchto osobních údajů. Správce má nastaveny mechanismy pro zajištění automatické
            anonymizace či výmazu osobních údajů v případě, že již nejsou potřeba k účelu, pro nějž byly zpracovávány. Pokud se
            subjekt údajů domnívá, že nedošlo k výmazu jeho osobních údajů, může se na nás obrátit písemně na adresu sídla
            správce. </p>
          <br />
          <h2 class="text-default"> 4. Právo na omezení zpracování </h2>
          <br />
          <p> Dle čl. 18 GDPR bude mít subjekt údajů do doby vyřešení podnětu právo na omezení zpracování, pokud bude popírat
            přesnost osobních údajů, důvody jejich zpracování nebo pokud podá námitku proti jejich zpracování, a to písemně na
            adresu sídla správce. </p>
          <br />
          <h2 class="text-default"> 5. Právo na oznámení opravy, výmazu nebo omezení zpracování </h2>
          <br />
          <p> Dle čl. 19 GDPR bude mít subjekt údajů právo na oznámení ze strany správce v případě opravy, výmazu nebo omezení
            zpracování osobních údajů. Dojde-li k opravě nebo výmazu osobních údajů, budeme informovat jednotlivé příjemce, s
            výjimkou případů, kdy se to ukáže jako nemožné nebo to vyžaduje nepřiměřené úsilí. Na základě žádosti subjektu údajů
            můžeme poskytnout informaci o těchto příjemcích. </p>
          <br />
          <h2 class="text-default"> 6. Právo na přenositelnost osobních údajů </h2>
          <br />
          <p> Dle čl. 20 GDPR bude mít subjekt údajů právo na přenositelnost údajů, které se ho týkají a které poskytl správci, ve
            strukturovaném, běžně používaném a strojově čitelném formátu, a právo požádat správce o předání těchto údajů
            jinému správci. </p>
          <br />
          <p> Pokud nám v souvislosti se smlouvou o poskytování služeb nebo na základě souhlasu poskytne subjekt údajů osobní
            údaje a jejich zpracování se provádí automatizovaně, má právo od nás získat takové údaje ve strukturovaném, běžně
            používaném a strojově čitelném formátu. Bude-li to technicky proveditelné, lze údaje předat i Vámi určenému správci,
            bude-li řádně určena osoba jednající za příslušného správce a bude možné ji autorizovat. </p>
          <br />
          <p> V případě, že by výkonem tohoto práva mohlo dojít k nepříznivému dotčení práv a svobod třetích osob, nelze vaší
            žádosti vyhovět. Žádost je možné uplatnit písemně na adresu sídla správce po doložení oprávněnosti požadavku.
          </p>
          <br />
          <h2 class="text-default"> 7. Právo vznést námitku proti zpracování osobních údajů </h2>
          <br />
          <p> Dle čl. 21 GDPR bude mít subjekt údajů právo vznést námitku proti zpracování jeho osobních údajů z důvodu
            oprávněného zájmu správce. </p>
          <br />
          <p> V případě, že správce neprokáže, že existuje závažný oprávněný důvod pro zpracování, který převažuje nad zájmy
            nebo právy a svobodami subjektu údajů, správce zpracování na základě námitky ukončí bez zbytečného odkladu.
            Námitku je možné poslat písemně na adresu sídla správce. </p>
          <br />
          <h2 class="text-default"> 8. Právo na odvolání souhlasu se zpracováním osobních údajů </h2>
          <br />
          <p> Souhlas se zpracováním osobních údajů pro obchodní účely účinný od 25. 5. 2018 je možné kdykoliv po tomto datu
            odvolat. Odvolání je zapotřebí učinit výslovným, srozumitelným a určitým projevem vůle, a to buď telefonicky na
            zákaznické lince anebo písemně na adresu sídla správce. </p>
          <br />
          <p> Zpracování údajů z cookies je možné zamezit nastavením webového prohlížeče. </p>
          <br />
          <h2 class="text-default"> 9. Automatizované individuální rozhodování včetně profilování </h2>
          <br />
          <p> Subjekt údajů má právo nebýt předmětem žádného rozhodnutí založeného výhradně na automatizovaném zpracování, včetně
            profilování, které by pro něj mělo právní účinky nebo se jej obdobným způsobem významně dotklo. Správce uvádí, že
            neprovádí automatizované rozhodování bez vlivu lidského posouzení s právními účinky pro subjekty údajů. </p>
          <br />
          <h2 class="text-default"> 10. Právo obrátit se na Úřad pro ochranu osobních údajů </h2>
          <br />
          <p> Subjekt údajů má právo obrátit se na Úřad pro ochranu osobních údajů (<a
            href="https://www.uoou.cz/">www.uoou.cz</a>). </p>
          <br />
          <p> Záležitosti výslovně neupravené těmito zásadami se řídí zákonem č. 110/2019, zákonem o zpracováních osobních údajů.
          </p>
          <br />
          <p> Tyto Zásady zpracování osobních údajů jsou účinné od 1. 6. 2023 a mohou být průběžně aktualizovány. Aktuální verzi
            vždy najdete na těchto webových stránkách. </p>
          <br />
          <p><b>Vitalii Paparyha - Papcraft </b></p>
          <br />
          <br />





        </div>
      </section>
    </div>
  );
};

export default GDPR;