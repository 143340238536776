import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSlidersByUser } from '../../redux/slices/sliders';
import SliderEditorForm from './SliderEditorForm';
import { Button, Layout, Tabs, Skeleton, message, Modal } from 'antd';
import { DesktopOutlined, TabletOutlined, MobileOutlined, ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import ResponsiveSlider from './ResponsiveSlider';
import { useParams, Link } from 'react-router-dom';
import { fetchUpdateSlider } from '../../apicalls/updateSlider';
import { generateResponsiveCSS } from './generateCSS';
import { debounce, cloneDeep } from 'lodash';
import food1 from "../../images/food1.jpg";
import food2 from "../../images/food2.jpg";
import food3 from "../../images/food3.jpg";
import food4 from "../../images/food4.jpg";
import food5 from "../../images/food5.jpg";
import food6 from "../../images/food6.jpg";
import food7 from "../../images/food7.jpg";
import food8 from "../../images/food8.jpg";
import food9 from "../../images/food9.jpg";
import urls from '../../config/Config';

const { Content, Sider } = Layout;

const SliderEditor = () => {
  const iframeRef = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.data);
  const sliders = useSelector(state => state.sliders.sliders);
  const [activeSlider, setActiveSlider] = useState(null);
  const [loading, setLoading] = useState(true);

  const [basicSettings, setBasicSettings] = useState(null);
  const [sliderDesktopSettings, setSliderDesktopSettings] = useState(null);
  const [sliderTabletSettings, setSliderTabletSettings] = useState(null);
  const [sliderMobileSettings, setSliderMobileSettings] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [sliderStyleDesktop, setSliderStyleDesktop] = useState(null);
  const [sliderStyleTablet, setSliderStyleTablet] = useState(null);
  const [sliderStyleMobile, setSliderStyleMobile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonImages, setButtonImages] = useState({ leftButtonImage: null, rightButtonImage: null });
  const [buttonImageUrls, setButtonImageUrls] = useState({ leftButtonImageUrl: '', rightButtonImageUrl: '' });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!sliders || sliders.length === 0) {
      dispatch(fetchSlidersByUser());
    }
  }, [dispatch, sliders]);

  useEffect(() => {
    if (sliders && sliders.length > 0) {
      const currentSlider = sliders.find(slider => slider.id === id);
      if (currentSlider) {
        setActiveSlider(currentSlider);
        const { settings } = currentSlider;
        setBasicSettings({
          name: currentSlider.name,
          extra_info: {
            type: currentSlider.extra_info.type,
            description: currentSlider.extra_info.description
          },
          settings: {
            link: settings.link,
            selector: settings.selector
          }
        })
        setSliderDesktopSettings(settings.js);
        const tabletSettings = settings.js.responsive?.find(res => res.breakpoint === 1200)?.settings;
        const mobileSettings = settings.js.responsive?.find(res => res.breakpoint === 768)?.settings;
        setSliderTabletSettings(tabletSettings || null);
        setSliderMobileSettings(mobileSettings || null);

        if (settings.definitionCSS) {
          if (settings.definitionCSS.sliderStyleDesktop) {
            setSliderStyleDesktop(settings.definitionCSS.sliderStyleDesktop);
          }
          setSliderStyleTablet(settings.definitionCSS.sliderStyleTablet || null);
          setSliderStyleMobile(settings.definitionCSS.sliderStyleMobile || null);
        }
        console.log(settings.definitionCSS);
        if (settings.definitionCSS.leftButtonImage) {
          setButtonImageUrls(prev => ({ ...prev, leftButtonImageUrl: settings.definitionCSS.leftButtonImage }));
        }
        if (settings.definitionCSS.rightButtonImage) {
          setButtonImageUrls(prev => ({ ...prev, rightButtonImageUrl: settings.definitionCSS.rightButtonImage }));
        }

        setLoading(false);
      }
    }
  }, [sliders, id]);
  const changeBasicSettings = (values) => {
    setBasicSettings(prevState => ({
      ...prevState,
      ...values,
      extra_info: {
        ...prevState.extra_info,
        ...values.extra_info
      },
      settings: {
        ...prevState.settings,
        ...values.settings
      }
    }));
  };
  const changeSliderSettings = (values) => {
    switch (activeTabKey) {
      case '1':
        setSliderDesktopSettings(values);
        break;
      case '2':
        setSliderTabletSettings(values);
        break;
      case '3':
        setSliderMobileSettings(values);
        break;
      default:
        break;
    }
  };

  const changeTab = (activeKey) => {
    setActiveTabKey(activeKey);
  };

  const handleSliderStyleChange = useCallback(
    debounce((changedValues, values) => {
      const keys = Object.keys(changedValues);
      if (keys[0] === 'leftButtonImage' || keys[0] === 'rightButtonImage') {
        return
      }
      switch (activeTabKey) {
        case '1':
          setSliderStyleDesktop(values);
          break;
        case '2':
          setSliderStyleTablet(values);
          break;
        case '3':
          setSliderStyleMobile(values);
          break;
        default:
          break;
      }
    }, 300),
    [activeTabKey]
  );
  const handleFileUpload = (file, fieldName) => {
    const fileUrl = URL.createObjectURL(file);
    setButtonImages(prevState => ({ ...prevState, [fieldName]: file }));
    setButtonImageUrls(prevState => ({ ...prevState, [`${fieldName}Url`]: fileUrl }));
  };

  const handleFileRemove = (fieldName) => {
    setButtonImages(prevState => ({ ...prevState, [fieldName]: null }));
    setButtonImageUrls(prevState => ({ ...prevState, [`${fieldName}Url`]: '' }));
  };
  const reloadIframe = () => {
    if (iframeRef.current) {
      const src = iframeRef.current.src;
      iframeRef.current.src = '';
      setTimeout(() => {
        iframeRef.current.src = src;
      }, 10);
    }
  };

  const saveChanges = async () => {
    const js = { ...sliderDesktopSettings };
    js.responsive = [];
    if (sliderTabletSettings) {
      js.responsive.push({ breakpoint: 1200, settings: sliderTabletSettings });
    }
    if (sliderMobileSettings) {
      js.responsive.push({ breakpoint: 768, settings: sliderMobileSettings });
    }
    const updatedSlider = {
      // ...activeSlider,
      name: basicSettings.name,
      extra_info: {
        // ...activeSlider.extra_info,
        ...basicSettings.extra_info
      },
      settings: {
        // ...activeSlider.settings,
        js,
        link: basicSettings.settings.link,
        selector: basicSettings.settings.selector,
        definitionCSS: {
          sliderStyleDesktop,
          sliderStyleTablet,
          sliderStyleMobile
        },
        css: generateResponsiveCSS(sliderStyleDesktop, sliderStyleTablet, sliderStyleMobile, buttonImageUrls, user, id)
      }
    };
  
    try {
      const formData = new FormData();
      formData.append('sliderData', JSON.stringify(updatedSlider));
      if (buttonImages.leftButtonImage) {
        formData.append('leftButtonImage', buttonImages.leftButtonImage);
      } else {
        formData.append('leftButtonImage', "delete");
      }
      if (buttonImages.rightButtonImage) {
        formData.append('rightButtonImage', buttonImages.rightButtonImage);
      } else {
        formData.append('rightButtonImage', "delete");
      }
      formData.append('baseUrl', urls.API_BASE_URL);
  
      const response = await fetch(`${urls.API_BASE_URL}/update-slider/${id}`, {
        method: 'PUT',
        body: formData,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
  
      if (response.ok) {
        message.success('Slider byl úspěšně aktualizován!');
        dispatch(fetchSlidersByUser());
        reloadIframe();
      } else {
        message.error('Chyba při aktualizaci slideru.');
      }
    } catch (error) {
      message.error('Chyba při aktualizaci slideru.');
      console.error(error);
    }
  };

  if (loading) {
    return <Skeleton active />;
  }

  const images = [food1, food2, food3, food4, food5, food6, food7, food8, food9];
  let sliderSettings;
  let maxWidth;
  let sliderStyle;
  switch (activeTabKey) {
    case '1':
      sliderSettings = sliderDesktopSettings;
      sliderStyle = sliderStyleDesktop;
      maxWidth = '100%';
      break;
    case '2':
      sliderSettings = sliderTabletSettings || sliderDesktopSettings;
      sliderStyle = sliderStyleTablet || sliderStyleDesktop;
      maxWidth = '1199px';
      break;
    case '3':
      sliderSettings = sliderMobileSettings || sliderDesktopSettings;
      sliderStyle = sliderStyleMobile || sliderStyleDesktop;
      maxWidth = '767px';
      break;
    default:
      sliderSettings = sliderDesktopSettings;
      sliderStyle = sliderStyleDesktop;
  }
  const items = [
    {
      key: '1',
      label: <div style={{ textAlign: 'center' }}><DesktopOutlined style={{ fontSize: '32px' }} /><div>{'>1200'}</div></div>,
      children: <SliderEditorForm
        basicSettings={basicSettings}
        changeBasicSettings={changeBasicSettings}
        title='Desktop settings'
        sliderStyle={sliderStyle}
        sliderSettings={sliderDesktopSettings}
        handleSliderStyleChange={handleSliderStyleChange}
        handleSubmit={changeSliderSettings}
        handleFileUpload={handleFileUpload}
        handleFileRemove={handleFileRemove}
        buttonImages={buttonImages}
        buttonImageUrls={buttonImageUrls} />,
    },
    {
      key: '2',
      label: <div style={{ textAlign: 'center' }}><TabletOutlined style={{ fontSize: '32px' }} /><div>{'<1200px'}</div></div>,
      children: <SliderEditorForm
        basicSettings={basicSettings}
        changeBasicSettings={changeBasicSettings}
        title='Tablet settings'
        sliderStyle={sliderStyle}
        sliderSettings={sliderSettings}
        handleSliderStyleChange={handleSliderStyleChange}
        handleSubmit={changeSliderSettings}
        handleFileUpload={handleFileUpload}
        handleFileRemove={handleFileRemove}
        buttonImages={buttonImages}
        buttonImageUrls={buttonImageUrls} />,
    },
    {
      key: '3',
      label: <div style={{ textAlign: 'center' }}><MobileOutlined style={{ fontSize: '24px' }} /><br />{'<768px'}</div>,
      children: <SliderEditorForm
        basicSettings={basicSettings}
        changeBasicSettings={changeBasicSettings}
        title='Mobile settings'
        sliderStyle={sliderStyle}
        sliderSettings={sliderSettings}
        handleSliderStyleChange={handleSliderStyleChange}
        handleSubmit={changeSliderSettings}
        handleFileUpload={handleFileUpload}
        handleFileRemove={handleFileRemove}
        buttonImages={buttonImages}
        buttonImageUrls={buttonImageUrls} />,
    },
  ];
  console.log(user);
  const modalBody = (
    <div>
      <h2 style={{ textAlign: 'center' }}>Záhlaví (před koncovým tagem HEAD)</h2>
      <p><code>{`<link rel="stylesheet" type="text/css" href="${urls.API_BASE_URL}/static/css/papcraftSliderCss.css" />`}</code></p>
      <p><code>{`<link rel="stylesheet" type="text/css" href="${urls.API_BASE_URL}/files/${user.id}/sliders/${id}/slider.css" /> `}</code></p>
      <h2 style={{ textAlign: 'center' }}>Zápatí (před koncovým tagem BODY)</h2>
      <p><code>{`<script src="${urls.API_BASE_URL}/static/js/papcraftSliderJS.js"></script> `}</code></p>
      <p><code>{` <script src="${urls.API_BASE_URL}/files/${user.id}/sliders/${id}/slider.js"></script>`}</code></p>
      <h2 style={{ textAlign: 'center' }}>Pokud není připojeno jQuery nebo si nejste jistí, přidejte tento řádek též do zápatí</h2>
      <p><code>{`<script src="https://code.jquery.com/jquery-3.6.0.min.js"></script> `}</code></p>
    </div>
  );
  console.log(activeSlider);
 if(activeSlider){
  return (
    <>
      <Modal title="Vkládací kód" width={"60%"} open={isModalOpen} footer={false} onCancel={handleCancel}>
        {modalBody}
      </Modal>
      <div style={{ display: 'flex', justifyContent: "space-between", padding: "0px 10px 20px 10px" }}>
        <Button type='text'>
          <Link to='/sliders'>
            <ArrowLeftOutlined /> Zpět
          </Link>
        </Button>
        <div >
          <Button type='primary' onClick={showModal} style={{ marginRight: "10px" }}>
            Vkládací kód
          </Button>
          <Button type='primary' onClick={saveChanges}>
            <SaveOutlined />Uložit změny
          </Button>
        </div>
      </div>
      <Layout>
        <Sider theme="light" width='500'>
          <Tabs
            activeKey={activeTabKey}
            type="card"
            defaultActiveKey="1"
            items={items}
            onChange={changeTab}
            tabPosition="bottom"
          />
        </Sider>
        <Content  style={{display:'flex',justifyContent:'center'}}>
          <iframe
            ref={iframeRef}
            src={activeSlider.settings.link}
            title="External Site"
            style={{ width: '100%', height: '100%', border: 'none',maxWidth:maxWidth,margin:'0 auto' }}
            loading='lazy'
             sandbox="allow-scripts allow-same-origin"
          />
          {/* <ResponsiveSlider maxWidth={maxWidth} sliderStyle={sliderStyle} images={images} defaultSettings={sliderSettings} buttonImageUrls={buttonImageUrls}/> */}
        </Content>
      </Layout>
    </>
  );
 }
    
  
}

export default SliderEditor;
