import React, { useState } from "react";
import { Button, Modal, Form, Input, Menu, Drawer, Select } from 'antd';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MenuOutlined } from '@ant-design/icons';
import logo from "../img/logo.svg";
import logowhite from "../img/logoWhite.svg";

export const AppHeader = ({ scrolled, isAuthenticated, registration, onFinish, exit, isModalOpen, showModal, modalType, handleCancel }) => {
  const changeAuth = useSelector((state) => state.auth.data);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const menu = (
    <Menu>
      {isAuthenticated ? (
        <Menu.Item key="1" onClick={exit}>
          Exit
        </Menu.Item>
      ) : (
        <>
          <Menu.Item key="2" onClick={() => showModal("login")}>
            Přihlásit se
          </Menu.Item>
          <Menu.Item key="3" onClick={() => showModal('registration')}>
            Registrace
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  let modalBody = null;
  if (modalType === 'registration') {
    modalBody = (
      <Form
        name="registration"
        layout="vertical"
        onFinish={registration}
      >
        <Form.Item
          name='username'
          label="Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='email'
          label="Email"
          rules={[
            {
              required: true,
              type: 'email',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='shop_link'
          label="Shop link"
          rules={[
            {
              required: true,
              message: 'Please input shop link!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Platform"
          name='platform_name'
          rules={[
            {
              required: true,
              message: 'Please select Platform!',
            },
          ]}
        >
          <Select
            style={{
              width: "100%",
            }}
            options={[
              {
                value: 'shoptet',
                label: 'Shoptet',
              },
              {
                value: 'upgates',
                label: 'Upgates',
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Registration
          </Button>
        </Form.Item>
      </Form>
    );
  } else {
    modalBody = (
      <Form
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        layout='vertical'
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }

  return (
    <>
      <Modal title={modalType === 'registration' ? 'Registrace' : "Přihlásit se"} open={isModalOpen} footer={false} onCancel={handleCancel}>
        {modalBody}
      </Modal>
      <div style={{ display: 'flex', justifyContent: 'center', height: "100%" }}>
        <div className={!isAuthenticated ? "header_wraper" : 'header_wraper_auth'}>
          <Link to='/' style={{ width: '150px', height: '37px' }}>
            {!isAuthenticated && !scrolled ? <img src={logowhite} width="100%" height='100%' /> : <img src={logo} width="100%" height='100%' />}
          </Link>

          <div className="desktop-menu">
            {isAuthenticated ? (
              <div>
                <span className={!isAuthenticated && !scrolled ? 'nav-link' : 'nav-scrolled'}>{changeAuth.username}</span>
                <Button className={!isAuthenticated && !scrolled ? 'nav-link' : 'nav-scrolled'} type="link" onClick={exit}>Exit</Button>
              </div>
            ) : (
              <div>
                <Button className={!isAuthenticated && !scrolled ? 'nav-link' : 'nav-scrolled'} type="link" onClick={() => showModal("login")}>Přihlásit se</Button>
                <Button className={!isAuthenticated && !scrolled ? 'nav-link' : 'nav-scrolled'} type="link" onClick={() => showModal('registration')}>Registrace</Button>
              </div>
            )}
          </div>

          <div className="mobile-menu">
            <Button
              className={!isAuthenticated && !scrolled ? 'nav-link' : 'nav-scrolled'}
              type="link"
              icon={<MenuOutlined style={{ fontSize: '32px' }} />}
              onClick={toggleDrawer}
            />
            <Drawer
              placement="right"
              closable={true}
              onClose={toggleDrawer}
              visible={drawerVisible}
            >
              {menu}
            </Drawer>
          </div>
        </div>
      </div>
    </>
  );
};
