import React, { Component } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import next from "../../img/next.png";
import prev from "../../img/prev.png";
class ResponsiveSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSettings: props.defaultSettings,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.defaultSettings !== this.props.defaultSettings) {
      this.setState({ currentSettings: this.props.defaultSettings });
    }
  }

  render() {
    const { images, maxWidth, sliderStyle, buttonImageUrls } = this.props;
    const { currentSettings } = this.state;

    const StyledSlider = styled(Slider)`
            width: 90%;
            margin: 20px auto;
            .slick-slide div {
                position: relative;
                width: ${sliderStyle.width + sliderStyle.suffixW};
                height: ${sliderStyle.height + sliderStyle.suffixH};
                margin: 0 auto;
                overflow: hidden;
            }

            .slick-slide div img {
                position: absolute;
                width: 100%;
                height: 100%;
                inset: 0;
                object-fit: ${sliderStyle.objectFit};
            }

            .slick-arrow {
              position: absolute;
              top: ${sliderStyle.posTopBtn + '%'};
              z-index: 1;
            }

            .slick-next {
              z-index: 1;
              width: ${sliderStyle.sizeButtons + 'px'};
              height: ${sliderStyle.sizeButtons + 'px'};
              background-image: url(${buttonImageUrls.rightButtonImageUrl || next});
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              right: ${sliderStyle.posRightBtn + '%'};
            }

            .slick-prev {
              z-index: 1;
              width: ${sliderStyle.sizeButtons + 'px'};
              height: ${sliderStyle.sizeButtons + 'px'};
              background-image: url(${buttonImageUrls.leftButtonImageUrl || prev});
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              left: ${sliderStyle.posLeftBtn + '%'};
            }
            .slick-next:before {
              content:none;
            }

            .slick-prev:before {
              content: none;
            }
           

            .slick-dots li.slick-active button:before {
                color: ${sliderStyle.colorDotActive};
            }
            .slick-dots li button:before {
                font-size: ${sliderStyle.sizeDot + 'px'};
                color: ${sliderStyle.colorDot};
                opacity: 1;

            }
        `;
  // transform: translate(${sliderStyle.posLeftBtn + '%'}, ${sliderStyle.posTopBtn + '%'});
  // transform: translate(${sliderStyle.posRightBtn + '%'}, ${sliderStyle.posTopBtn + '%'});
    return (
      <div style={{ maxWidth: maxWidth, margin: '0 auto' }}>
        <StyledSlider {...currentSettings} className="slickSlider">
          {images.map((image, index) => (
            <img src={image} alt={`Slide ${index + 1}`} key={index} />
          ))}
        </StyledSlider>
      </div>
    );
  }
}

export default ResponsiveSlider;
