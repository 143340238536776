import React from 'react';
import { Button, Input, Upload, message, Row, Col, Statistic, Space, List, Modal, Popconfirm } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';


class LearnWords extends React.Component {
  state = {
    words: [],
    currentWordIndex: 0,
    userInput: '',
    incorrectAttempts: 0,
    retryInputs: Array(4).fill(''),
    learnedWordsCount: 0,
    isModalVisible: false,
    learnedWords: [],
    retryInputErrors: Array(4).fill(false),
  };

  componentDidMount() {
    const learnedWords = JSON.parse(localStorage.getItem('learnedWords')) || [];
    this.setState({ learnedWordsCount: learnedWords.length });
  }
  componentDidUpdate(prevProps, prevState) {
    // Установите фокус на первый инпут после каждого обновления состояния, когда появляются инпуты для ввода
    if (this.inputRef0 && (prevState.currentWordIndex !== this.state.currentWordIndex || prevState.incorrectAttempts !== this.state.incorrectAttempts || prevState.words.length !== this.state.words.length || prevState.retryInputs.length !== this.state.retryInputs.length)) {
      this.inputRef0.focus();
    }
  }
  handleFileRead = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const lines = text.trim().split(',\r\n');
      const learnedWords = JSON.parse(localStorage.getItem('learnedWords')) || [];
      const learnedWordsSet = new Set(learnedWords.map(word => word.en.toLowerCase()));
      const words = lines.map(line => {
        if (line !== "") {
          const parts = line.trim().split(' - ');
          if (!learnedWordsSet.has(parts[0].toLowerCase())) {
            return { en: parts[0], ua: parts[1], learned: false };
          }
        }
        return null;
      }).filter(Boolean);
      this.setState({ words });
    };
    reader.readAsText(file);
    return false;
  };

  handleInputChange = (e) => {
    this.setState({ userInput: e.target.value });
  };

  handleRetryInputChange = (index, e) => {
    let retryInputs = [...this.state.retryInputs];
    retryInputs[index] = e.target.value;
    this.setState({ retryInputs });
  };

  preventPaste = (e) => {
    e.preventDefault();
    message.warning('Гарна спроба :-) але потрібно писати');
  };

  checkAnswer = () => {
    const { words, currentWordIndex, userInput } = this.state;
    if (words[currentWordIndex].en.toLowerCase() === userInput.toLowerCase()) {
      words[currentWordIndex].learned = true;


      let learnedWords = JSON.parse(localStorage.getItem('learnedWords')) || [];
      if (!learnedWords.some(word => word.en === words[currentWordIndex].en)) {

        learnedWords.push(words[currentWordIndex]);
        localStorage.setItem('learnedWords', JSON.stringify(learnedWords));
      }

      this.setState({
        words,
        currentWordIndex: currentWordIndex + 1,
        userInput: '',
        incorrectAttempts: 0,
        retryInputs: Array(4).fill(''),
        learnedWordsCount: learnedWords.length,
      });
      message.success('Правильно!');
    } else {
      this.setState({ incorrectAttempts: 1 });
      message.error('Неправильно. Потрібно вчити');
    }
  };

  checkRetryAnswer = () => {
    const { words, currentWordIndex, retryInputs } = this.state;
    const correctAnswer = words[currentWordIndex].en.toLowerCase().trim();
  
    // Проверяем каждый ввод на соответствие правильному ответу, приводя его к нижнему регистру и удаляя пробелы
    const retryInputErrors = retryInputs.map(input => input.toLowerCase().trim() !== correctAnswer);
  
    if (retryInputErrors.every(error => !error)) { // Если все ответы правильные
      // Слово считается изученным, добавляем его в localStorage, если оно там уже не записано
      let learnedWords = JSON.parse(localStorage.getItem('learnedWords')) || [];
      if (!learnedWords.some(word => word.en.toLowerCase() === correctAnswer)) {
        words[currentWordIndex].learned = true;
        learnedWords.push(words[currentWordIndex]);
        localStorage.setItem('learnedWords', JSON.stringify(learnedWords));
      }
  
      // Обновляем состояние для перехода к следующему слову, сбрасываем ввод пользователя и ошибки
      this.setState(prevState => ({
        currentWordIndex: prevState.currentWordIndex + 1,
        userInput: '',
        incorrectAttempts: 0,
        retryInputs: Array(4).fill(''), // Обнуляем инпуты для повторения
        retryInputErrors: Array(4).fill(false), // Сбрасываем ошибки
        learnedWordsCount: learnedWords.length,
        learnedWords // Обновляем список изученных слов
      }));
      message.success('Молодець! Все правильно.');
    } else {
      // Если есть ошибки, обновляем состояние ошибок, чтобы отразить их в UI
      this.setState({ retryInputErrors });
      message.error('Є помилки в введених словах. Спробуй ще раз.');
    }
  };
  
  
  

  handleRetryKeyDown = (e, index) => {
    if (e.key === 'Enter') {
      if (index < this.state.retryInputs.length - 1) {

        this[`inputRef${index + 1}`].focus();
      } else {

        this.checkRetryAnswer();
      }
    }
  };

  renderRetryInputs = () => {
    const { retryInputs, retryInputErrors } = this.state;
    return retryInputs.map((input, index) => (
      <Input
        key={index}
        value={input}
        onChange={(e) => this.handleRetryInputChange(index, e)}
        onKeyDown={(e) => this.handleRetryKeyDown(e, index)}
        placeholder={`Напиши переклад ${index + 1}`}
        ref={(input) => this[`inputRef${index}`] = input}
        style={{
          marginBottom: 10,
          borderColor: retryInputErrors[index] ? 'red' : 'initial', // Изменение цвета границы при ошибке
        }}
        onPaste={this.preventPaste}
      />
    ));
  };

  showModal = () => {
    const learnedWords = JSON.parse(localStorage.getItem('learnedWords')) || [];
    this.setState({
      learnedWords: learnedWords,
      isModalVisible: true,
    });
  };

  handleOk = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  handleDelete = (en) => {
    const learnedWords = this.state.learnedWords.filter(word => word.en !== en);
    this.setState({ learnedWords, learnedWordsCount: learnedWords.length });
    localStorage.setItem('learnedWords', JSON.stringify(learnedWords));
  };

  resetLearnedWords = () => {
    this.setState({ learnedWords: [], learnedWordsCount: 0 });
    localStorage.setItem('learnedWords', JSON.stringify([]));
  };
  cancel = (e) => {
    message.error('Хух нічого не стер )))');
  };
  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.checkAnswer();
    }
  };
  render() {
    const { words, currentWordIndex, userInput, incorrectAttempts, learnedWordsCount, isModalVisible, learnedWords } = this.state;
    const currentWord = words[currentWordIndex];
    return (
      <Row justify="center" align="middle" style={{ minHeight: '100vh',maxWidth:'50%', margin:'0 auto' }}>
        <Col>
          <div style={{ textAlign: 'center' }}>
            {words.length > 0 ? null : <Upload beforeUpload={this.handleFileRead} showUploadList={false}>
              <Button icon={<UploadOutlined />}>Загрузити файл з словами</Button>
            </Upload>}
            {currentWord && (
              <div style={{ marginTop: 20 }}>
                <h2>Слово: {currentWord.ua}</h2>
                <div onClick={this.showModal}>
                  <Statistic title="Вивчено слів" value={learnedWordsCount} style={{ cursor: 'pointer' }} />
                </div>

                <Modal title="Вивчені слова" open={isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel} footer={[
                  <Button key="submit" type="primary" onClick={this.handleOk}>
                    ОК
                  </Button>,
                ]}>
                  <div style={{ textAlign: 'end' }}>
                    <Popconfirm
                      title="Видалити всі слова"
                      description="Ти справді хочеш почати спочатку?"
                      onConfirm={this.resetLearnedWords}
                      onCancel={this.cancel}
                      okText="Так я мазахіст)"
                      cancelText="Ні "
                    >
                      <Button danger>Вчити заново</Button>
                    </Popconfirm>
                  </div>
                  <List
                    itemLayout="horizontal"
                    dataSource={learnedWords}
                    renderItem={item => (
                      <List.Item>
                        <List.Item.Meta
                          title={<Space>{item.en} - {item.ua}<DeleteOutlined onClick={() => this.handleDelete(item.en)} style={{ color: 'red', marginLeft: '10px', cursor: 'pointer' }} /></Space>}
                        />
                      </List.Item>
                    )}
                  />
                </Modal>
                {incorrectAttempts < 1 ? (
                  <>
                    <Input
                      value={userInput}
                      onChange={this.handleInputChange}
                      placeholder="Напиши переклад"
                      style={{ marginBottom: 10 }}
                      onKeyDown={this.handleKeyDown}
                    />
                    <Button onClick={this.checkAnswer} style={{ marginBottom: 20 }}>Перевірити</Button>
                  </>
                ) : (
                  <>
                    <h2>Переклад <span style={{color:'blue'}}>{currentWord.en}</span></h2>
                    <h3 style={{marginBottom:'20px'}}>Напиши його декілька раз щоб вивчити:</h3>
                    {this.renderRetryInputs()}
                    <Button onClick={this.checkAnswer}>Перевірити</Button>
                  </>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

export default LearnWords;
