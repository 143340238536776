import React from 'react';
import './reference.css';
import kafcocajda from "../../img/projects/kafcocajda.jpg";
import ceskesvicky from "../../img/projects/ceskesvicky2.jpg";
import loznice from "../../img/projects/loznice.jpg";
import noze from "../../img/projects/noze2.jpg";
import tilis from "../../img/projects/tilis.jpg";
import realbi from "../../img/projects/realbi.jpg";
import madx from "../../img/projects/madx.jpg";
import transfay from "../../img/projects/transfay.jpg";

const Reference = () => {
  return (
    <div className="main-section">
     <section className="section section--reference">
      <div className="section__container">
       <h2 className="section__title">Další reference</h2>
        <div className="reference">
          <div className="reference__picture_l padding">        
          <a href="https://www.kafco-cajda.cz"  target="_blank" >
            <img src={kafcocajda} className="rounded-5rem" width="100%" alt="Kafčo-Čajda"/>
          </a>
          </div>
          <div className="reference__text padding">
            <div className="reference__item">
            <h2 className="h3">Kafčo-Čajda</h2>
              <span className="reference__badge">Implementace pluginu</span>
              <span className="reference__badge">Přenos e-shopu</span>
              <span className="reference__badge">Překódování šablony</span>
                <p className="reference__description">Web Kafčo-Čajda nabízí možnost vytvořit si vlastní směs kávy a čaje podle osobní chuti. Umožňuje zákazníkům vybrat si z prémiové kávy a čaje z různých zemí, přidat na obal oblíbenou fotografii a věnování, což dělá z každého produktu jedinečný a osobní dárek pro každou příležitost. Nabízí také dárkové balení a služby pro firmy.</p>
                  <a href="https://www.kafco-cajda.cz" target="_blank" className="btn-outline-primary">Přejít na web
                  </a>
            </div>
          </div>
        </div>

        <div className="reference reference_2">
          <div className="reference__text padding">
            <div className="reference__item_2">
            <h2 className="h3">České Svíčky</h2>
            <span className="reference__badge">Implementace pluginu</span>
              <span className="reference__badge">Přenos e-shopu</span>
              <span className="reference__badge">Design</span>
                <p className="reference__description">Web České svíčky se specializuje na prodej ručně vyrobených svíček a nabízí široký sortiment dekorativních, reklamních, svatebních a vánočních svíček. Mezi jejich nejoblíbenější produkty patří "lodičky štěstí", které jsou určené k pouštění na vodní hladinu. Firma působí na trhu již 17 let a klade důraz na kvalitu a originální českou výrobu. Svíčky jsou vyrobené z kvalitních materiálů a mají široké využití jak pro osobní potřebu, tak pro speciální příležitosti.  </p>
                <a href="https://www.ceskesvicky.cz/" target="_blank" className="btn-outline-primary">Přejít na web
                  </a>
              </div>
            </div>
          <div className="reference__picture_r padding">    
          <a href="https://www.ceskesvicky.cz/" target="_blank">
              <img src={ceskesvicky} alt="České Svíčky" className="rounded-5rem" width="100%" />    
          </a>
          </div>
        </div>

        <div className="reference">
          <div className="reference__picture_l padding">        
           <a href="https://www.loznice21.cz/" target="_blank" >
            <img src={loznice} className="rounded-5rem" width="100%" alt="Ložnice 21"/>
          </a>
          </div>
          <div className="reference__text padding">
            <div className="reference__item">
            <h2 className="h3">Ložnice 21</h2>
              <span className="reference__badge">Implementace pluginu</span>
              <span className="reference__badge">Přenos e-shopu</span>
              <span className="reference__badge">Překódování šablony</span>
                <p className="reference__description">Ložnice21 je český online obchod specializující se na prodej ložnicového textilu. Nabízí široký sortiment produktů včetně matrací, povlečení, polštářů a přikrývek. Kladou důraz na kvalitu a komfort, poskytují produkty s termoregulačními a antialergickými vlastnostmi. Dále nabízí zboží jako jsou podložky a toppery pro zvýšení pohodlí spaní. Jejich web také informuje o slevách a speciálních nabídkách.</p>
                <a href="https://www.loznice21.cz/" target="_blank"  className="btn-outline-primary">Přejít na web
                  </a>
            </div>
          </div>
        </div>

        <div className="reference reference_2">
          <div className="reference__text padding">
            <div className="reference__item_2">
            <h2 className="h3">Damaskové Nože</h2>
            <span className="reference__badge">Přenos e-shopu</span>
              <span className="reference__badge">Import produktů</span>
              <span className="reference__badge">Grafické úpravy</span>
                <p className="reference__description">Webové stránky damaskove-noze.cz se specializují na prodej nožů vyrobených z damaškové oceli, které jsou známé pro svou kvalitu a ostrost. Nabízí širokou škálu nožů, včetně loveckých, kuchyňských, outdoorových a sběratelských. Mimo to nabízí i možnost gravírování nožů, což je ideální pro personalizované dárky. Dalšími produkty jsou různé příslušenství, jako pouzdra na nože, a také kapesní nože renomovaných značek.</p>
                <a href="https://www.damaskove-noze.cz/" target="_blank" className="btn-outline-primary">Přejít na web
                  </a>
              </div>
            </div>
          <div className="reference__picture_r padding">    
          <a href="https://www.damaskove-noze.cz/" target="_blank">
              <img src={noze} alt="Damaskové Nože" className="rounded-5rem" width="100%" />    
          </a>
          </div>
        </div>

        <div className="reference">
          <div className="reference__picture_l padding">        
           <a href="https://play.google.com/store/apps/details?id=kz.tilis.store&pcampaignid=web_share" target="_blank" >
            <img src={tilis} className="rounded-5rem" width="100%" alt="tilis.kz"/>
          </a>
          </div>
          <div className="reference__text padding">
            <div className="reference__item">
            <h2 className="h3">Tilis.kz</h2>
              <span className="reference__badge">Mobilní aplikace</span>
              <span className="reference__badge">Design</span>
              <span className="reference__badge">Grafické úpravy</span>
                <p className="reference__description">Tilis.kz je mobilní aplikace zaměřená na trh v Kazachstánu, která umožňuje uživatelům pohodlně nakupovat potraviny a další zboží s doručením až domů. Aplikace je navržena tak, aby poskytovala rychlý a efektivní způsob objednávání produktů z pohodlí domova, což zahrnuje širokou nabídku zboží od místních obchodů a supermarketů. Tilis.kz se snaží usnadnit každodenní nákupy tím, že zákazníkům umožňuje vyhnout se časově náročným návštěvám obchodů a nabízí pohodlnou alternativu pro nákup potřebných potravin a produktů.</p>
                <a href="https://play.google.com/store/apps/details?id=kz.tilis.store&pcampaignid=web_share" target="_blank"  className="btn-outline-primary">Přejít na aplikaci
                  </a>
            </div>
          </div>
        </div>

        <div className="reference reference_2">
          <div className="reference__text padding">
            <div className="reference__item reference__item_2">
            <h2 className="h3">Realbi</h2>
            <span className="reference__badge">Prezentační web</span>
              <span className="reference__badge">Přenos webu</span>
                <p className="reference__description">Realbi je společnost která nabízí služby v oblasti projektového managementu a konsultací. Na svých webových stránkách poskytují kontaktní informace pro zájemce o jejich služby, včetně telefonních čísel a emailových adres kontaktů.</p>
                <a href="https://realbi.net/" target="_blank" className="btn-outline-primary">Přejít na web
                  </a>
              </div>
            </div>
          <div className="reference__picture_r padding">    
          <a href="https://realbi.net/" target="_blank">
              <img src={realbi} alt="Realbi" className="rounded-5rem" width="100%" />    
          </a>
          </div>
        </div>


        <div className="reference">
          <div className="reference__picture_l padding">        
           <a href="https://madx.cz" target="_blank" >
            <img src={madx} className="rounded-5rem" width="100%" alt="tilis.kz"/>
          </a>
          </div>
          <div className="reference__text padding">
            <div className="reference__item">
            <h2 className="h3">MadX</h2>
              <span className="reference__badge">Design</span>
              <span className="reference__badge">Grafické úpravy</span>
                <p className="reference__description">MADX.cz nabízí širokou škálu produktů pro zdraví a pohodu, včetně CBD olejů, kosmetiky a produktů z kratomu, které jsou pečlivě vybírané a testované. Klade důraz na kvalitu a bezpečnost. CBD a další kanabinoidy jsou ceněny pro své účinky na snížení stresu a bolesti, zlepšení spánku a celkovou podporu zdraví. Kratom je oceněn pro své stimulační a relaxační účinky. Spokojenost zákazníků je prioritou společnosti.</p>
                <a href="https://madx.cz" target="_blank"  className="btn-outline-primary">Přejít na web
                  </a>
            </div>
          </div>
        </div>

        
        <div className="reference reference_2">
          <div className="reference__text padding">
            <div className="reference__item reference__item_2">
            <h2 className="h3">Transfay</h2>
            <span className="reference__badge">Prezentační web</span>
            <span className="reference__badge">Design</span>
            <span className="reference__badge">Mobilní aplikace</span>
                <p className="reference__description">Transfay je inovativní platforma určená pro zjednodušení logistiky pro dopravce a majitele nákladů. Umožňuje rychlé a efektivní vyhledávání dostupných nákladů po celé Evropě, což značně zlepšuje efektivitu plánování přepravy. Program je navržen tak, aby poskytoval uživatelům snadný přístup k aktuálním nabídkám a potřebám v oblasti nákladní dopravy, zefektivňuje komunikaci mezi stranami a usnadňuje celý proces přepravy.</p>
                <a href="https://play.google.com/store/apps/details?id=com.transfay&pcampaignid=web_share" target="_blank" className="btn-outline-primary">Přejít na aplikaci
                  </a>
              </div>
            </div>
          <div className="reference__picture_r padding">    
          {/* <a href="https://realbi.net/" target="_blank"> */}
              <img src={transfay} alt="Realbi" className="rounded-5rem" width="100%" />    
          {/* </a> */}
          </div>
        </div>

      </div>
     </section>
    </div>
  );
};

export default Reference;
