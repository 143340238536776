import React from 'react';
import './projectsSection.css'; // подключение файла со стилями
import kafcocajda from "../../img/projects/kafcocajda.jpg";
import ceskesvicky from "../../img/projects/ceskesvicky.jpg";
import loznice from "../../img/projects/loznice.jpg";
import noze from "../../img/projects/noze.jpg";
import { Link } from 'react-router-dom';

const ProjectsSection = () => {
  return (
    <section className="section section--projects">
      <div className="section__container">
        <h2 className="section__title">Naše práce</h2>
        <p className="section__description">Vytváříme webové stránky, e-shopy, webové aplikace, design, UX/UI design a ilustrace, automatizujeme obchodní procesy. Pomůžeme vám dosáhnout nové úrovně ve vašem podnikání, zefektivnit procesy a zvýšit konkurenceschopnost na trhu.</p>
        <div className="projects">
          <div className="projects__item projects__item--small">
            <a href="https://www.ceskesvicky.cz/" target="_blank" rel="noopener noreferrer">
              <img src={ceskesvicky} alt="České Svíčky" className="projects__img" />
              <div className="projects__overlay">
                <h3 className="projects__title">České Svíčky</h3>
                <p className="projects__description">Implementace pluginu / Design / Přenos e-shopu </p>
              </div>

            </a>
          </div>
          <div className="projects__item projects__item--large">
            <a href="https://www.kafco-cajda.cz/" target="_blank" rel="noopener noreferrer">
              <img src={kafcocajda} alt="Kafčo-Čajda" className="projects__img" />
              <div className="projects__overlay">
                <h3 className="projects__title">Kafčo-Čajda</h3>
                <p className="projects__description">Implementace pluginu  / Přenos e-shopu / Překódování šablony </p>
              </div>
            </a>
          </div>
        </div>
        <div className="projects2">

          <div className="projects__item projects__item--large">
            <a href="https://www.loznice21.cz/" target="_blank" rel="noopener noreferrer">
              <img src={loznice} alt="Ložnice 21" className="projects__img" />
              <div className="projects__overlay">
                <h3 className="projects__title">Ložnice 21</h3>
                <p className="projects__description">Přenos e-shopu / Překódování šablony</p>
              </div>
            </a>
          </div>
          <div className="projects__item projects__item--small">
          <a href="https://www.damaskove-noze.cz/" target="_blank" rel="noopener noreferrer">
            <img src={noze} alt="Damaskové Nože" className="projects__img" />
            <div className="projects__overlay">
              <h3 className="projects__title">Damaskové Nože</h3>
              <p className="projects__description">Přenos e-shopu / Import produktů / grafické úpravy</p>
            </div>
          </a>
        </div>
        </div>
        <div className="section__footer">
          <Link to="/reference" className="section__button">Další reference</Link>
        </div>
      </div>
    </section>
  );
};

export default ProjectsSection;
