import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";
import hedimg from "../../img/headerImg.png";
const Cover = () => {
  const particlesInit = useCallback(async engine => {
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
    await console.log(container);
  }, []);
  const scrollToSection = () => {
    const section = document.getElementById('mainHome');
    if (section) {
      const sectionTop = section.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: sectionTop - 58,
        behavior: 'smooth'
      });
    }
  };
  
  return (
    <div style={{
      height: '90vh',
    }}>
      <div className="particles-main" style={{
        height: '70vh',
        background: '#bacaea',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
          <Particles
      id="tsparticles"
      init={particlesInit}
      className="particles-container"
      loaded={particlesLoaded}
      options={{
        fpsLimit: 60,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 100,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#fff"
          },
          links: {
          color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          move: {
            direction: "none",
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: false,
            speed: 1,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 25,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "circle",
          },
          size: {
            value: { min: 1, max: 5 },
          },
        },
        detectRetina: true,
      }}
    />
        <section className="main-section-cover">
          <div className="main-section__text">
            <div className="main-section__text-inner">
              <h1 className="main-section__title">Tvoříme budoucnost z Vašich nápadů</h1>
              <span className="main-section__description">Umožňujeme obchodům růst prostřednictvím inovativních řešení. Naše platforma nabízí širokou škálu pluginů a služeb pro e-shopy, od grafického návrhu po vývoj na míru.</span>
              <div className="main-section__button-wrapper">
                <span href="" onClick={scrollToSection} className="main-section__button">Naše služby</span>
              </div>
            </div>
          </div>
          <div className="main-section__image-wrapper">
            <img src={hedimg} className="main-section__image" alt="Header" />
          </div>
        </section>
      </div>
      <svg width="100%" height="25vh" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" overflow="auto" style={{background:'#bacaea'}} shape-rendering="auto" fill="#bacaea">
        <defs>
          <path id="wavepath" d="M 0 2000 0 500 Q 150 283 300 500 t 300 0 300 0 300 0 300 0 300 0  v1000 z" />
          <path id="motionpath" d="M -600 0 0 0" />
        </defs>
        <g >
          <use xlinkHref="#wavepath" y="-21" fill="#f9f9ff">
            <animateMotion
              dur="10s"
              repeatCount="indefinite">
              <mpath xlinkHref="#motionpath" />
            </animateMotion>
          </use>
        </g>
      </svg>




    </div>


  );
};

export default Cover